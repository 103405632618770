<template>
  <div class="release">
    <div class="release-box" v-if="!showMap">
      <!-- navbar组件 -->
      <navbar title="发布房源" path="/" />
      <!-- 选择小区模块 -->
      <div class="res-box" v-if="showResidential">
        <!-- 选择小区搜索框 -->
        <!-- <van-search v-model="resName" show-action placeholder="请输入小区名称" @search="onSearch" @cancel="onCancel" /> -->
        <van-search v-model="resName" @input="inputFocus" show-action placeholder="请输入小区名称" @search="onSearch" autofocus>
          <template #action>
            <div @click="onSearch" v-if="flag">搜索</div>
            <div @click="onCancel" v-else>取消</div>
          </template>
        </van-search>
        <!-- 选择小区内容模块 -->
        <input type="hidden" v-model="uid">
        <div class="res-content" v-if="residentialList && residentialList.length > 0">
          <div class="res-item" @click="checkRes(index)" v-for="(item, index) in residentialList" :key="item.id">
            <div class="item-check">
              <img src="../assets/images/checked.png" v-if="index === resIndex" alt="" />
              <div class="check-box" v-else></div>
            </div>
            <div class="item-img">
              <img :src="item.pic  ? item.pic : require('../assets/images/placeholder.png')" alt="" />
            </div>
            <div class="item-msg">
              <div class="item-name">{{ item.name }}</div>
              <div class="item-desc">{{ item.address }}</div>
            </div>
          </div>
        </div>
        <van-empty v-else description="暂未收录该处楼盘" />

        <!-- 选择小区底部按钮 -->
        <div class="from-buttom">
          <van-button size="large" @click="confirm">确定</van-button>
        </div>
      </div>
      <!-- 表单页面 -->
      <div class="release-from" v-else>
        <!-- 表单上部分 -->
        <div class="release-box">
          <!-- 小区名称 -->
          <div class="from-item" @click="goResidential">
            <van-field required v-model="residential" label="小区" right-icon="arrow" input-align="right" placeholder="请选择小区" readonly />
          </div>
          <!-- 位置 -->
          <div class="from-item">
            <van-field required v-model="position" label="位置" input-align="right" placeholder="选择小区获取位置" readonly />
          </div>
          <!-- 楼层 户型 装修 -->
          <div class="from-item house-msg">
            <div class="house-item" @click="exhibitFloor('floor')">
              <div class="house-text required">楼层</div>
              <van-field v-model="popupList.floor" readonly placeholder="请选择" input-align="center" />
            </div>
            <div class="house-item" @click="exhibitFloor('type')">
              <div class="house-text required">户型</div>
              <van-field v-model="popupList.type" readonly placeholder="请选择" input-align="center" />
            </div>
            <div class="house-item" @click="exhibitFloor('trim')">
              <div class="house-text">装修</div>
              <van-field v-model="popupList.trim" readonly placeholder="请选择" input-align="center" />
            </div>
          </div>
          <!-- 面积 -->
          <div class="from-item">
            <van-field v-model="area" input-align="right" clearable required type="number" label="面积" placeholder="请输入">
              <template #extra>
                <div class="input-right">m²</div>
              </template>
            </van-field>
          </div>
          <!-- 售价 -->
          <div class="from-item">
            <van-field v-model="price" input-align="right" clearable required type="number" label="售价" placeholder="“0”万元表示面议">
              <template #extra>
                <div class="input-right">万元</div>
              </template>
            </van-field>
          </div>
          <!-- 信息标题 -->
          <div class="from-item">
            <van-field v-model="message" input-align="right" clearable label="信息标题" placeholder="请输入信息标题">
            </van-field>
          </div>
          <!-- 在线估价 -->
          <div class="from-item from-evaluation">
            <van-field v-model="evaluation" input-align="right" clearable type="number" label="房屋估价" placeholder="请输入">
              <template #extra>
                <div class="input-right">万元</div>
              </template>
            </van-field>
            <div class="eva-btn" @click="goEvaluation(residential)">在线估价</div>
          </div>
          <div class="from-item">
            <div class="radio-box">
              <span>是否支持按揭:</span>
              <van-radio-group v-model="paymentRadio" direction="horizontal">
                <van-radio name="0" icon-size="15px">否</van-radio>
                <van-radio name="1" icon-size="15px">是<span v-if="paymentRadio==1">，首付</span></van-radio>
              </van-radio-group>
              <van-field v-model="money" v-if="paymentRadio==1" placeholder="请输入" input-align="right" type="number" />
              <span v-if="paymentRadio==1" class="Company">万元</span>
            </div>

          </div>
          <!-- 联系人姓名 -->
          <div class="from-item">
            <van-field v-model="name" @input="nameFocus" input-align="right" clearable required label="联系人" placeholder="请输入联系姓名">
            </van-field>
          </div>
          <!-- 手机号码 -->
          <div class="from-item" style="border-bottom: 0">
            <van-field v-model="phone" input-align="right" clearable required label="手机" placeholder="请输入手机号码">
            </van-field>
          </div>
        </div>
        <!-- 中间按钮模块 -->
        <div class="from-more" @click="showBottom">继续补充更多信息></div>
        <!-- 表单下部分 -->
        <div class="bottom-box" v-if="showBottomFrom">
          <div class="upload-img">
            <!-- 上传图片模块 -->
            <van-uploader v-model="imgList" multiple>
              <template #default>
                <img src="../assets/images/uploadImg.png" alt="" />
              </template>
            </van-uploader>
            <!-- 上传视频模块 -->
            <van-uploader v-model="videoList" accept="video/*" :max-count="1">
              <template #default>
                <img src="../assets/images/uploadVideo.png" />
              </template>
            </van-uploader>
            <!-- 房屋类型 -->
            <div class="from-item" @click="exhibitFloor('houseType')">
              <van-field v-model="popupList.houseType" label="房屋类型" right-icon="arrow" input-align="right" placeholder="请选择类型" readonly />
            </div>
            <!-- 房屋朝向 -->
            <div class="from-item" @click="exhibitFloor('orientation')">
              <van-field v-model="popupList.orientation" label="朝向" right-icon="arrow" input-align="right" placeholder="请选择朝向" readonly />
            </div>
            <!-- 年代 电梯 权属 -->
            <div class="from-item house-msg">
              <div class="house-item" @click="exhibitFloor('years')">
                <div class="house-text">年代</div>
                <van-field v-model="popupList.years" readonly placeholder="请选择" input-align="center" />
              </div>
              <div class="house-item" @click="exhibitFloor('lift')">
                <div class="house-text">电梯</div>
                <van-field v-model="popupList.lift" readonly placeholder="无" input-align="center" />
              </div>
              <div class="house-item" @click="exhibitFloor('ownership')">
                <div class="house-text">权属</div>
                <van-field v-model="popupList.ownership" readonly placeholder="请选择" input-align="center" />
              </div>
            </div>
            <!-- 特色标签 -->
            <div class="label from-item">
              <div class="label-title">特色标签</div>
              <div class="label-box">
                <div :class="[allCheck ? 'active label-item' : 'label-item']" @click="allChecked">
                  全选
                </div>
                <div :class="[item.check ? 'active label-item' : 'label-item']" v-for="(item, index) in ladelList" :key="index" @click="checkLabel(index)">
                  {{ item.text }}
                </div>
              </div>
            </div>
            <!-- 信息补充 -->
            <div class="information">
              <div class="title">信息补充:</div>
              <van-field v-model="information" rows="5" type="textarea" placeholder="请输入描述文字" />
            </div>
            <!-- 房源介绍 -->
            <div class="introduce">
              <div class="title">房源介绍:</div>
              <quill-editor v-model="content" ref="myQuillEditor" :options="editorOption">
              </quill-editor>
            </div>
          </div>
        </div>
        <!-- 发布按钮 -->
        <div class="from-buttom">
          <van-button size="large" @click="releases">立即发布</van-button>
        </div>
      </div>
    </div>
    <div class="map-box" v-else>
      <van-nav-bar title="选择地址" fixed left-arrow placeholder @click-left="closeMap" />
      <iframe id="mapPage" style="clear:both;position:fixed;z-index:9999;" width="100%" height="100%" frameborder=0 src="https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=SUBBZ-OT7CJ-H6IFO-KYRB5-JGLGS-TBB36&referer=myapp">
      </iframe>
    </div>

    <!-- 弹窗组件 -->
    <popup :showPopup="showPopup" :columns="columns" @closePopup="closePopup" @checkPopup="getValue"></popup>
  </div>
</template>
<script>
import popup from "../components/Popup.vue";
import navbar from "../components/NavBar.vue";
import { isMobile, symbolEscape, escapeHtml } from "../common/validate";

export default {
  name: "release",
  data() {
    return {
      flag: false,
      uid: "",
      paymentRadio: "1",
      money: "",
      showResidential: false, //是否显示选择小区
      lat: "", //经纬度
      lng: "", //经纬度
      residential: "", //小区
      residentialid: "", //小区id
      position: "", //位置
      area: "", //面积
      price: "", //售价
      message: "", //信息标题
      evaluation: "", //房屋估价
      name: "", //联系人
      phone: "", //手机号码
      resName: "", //搜索小区内容
      showPopup: false, //是否弹窗
      showMap: false, //是否显示地图
      //所有小区列表
      residentialList: [],
      resIndex: "", // 选中的小区索引
      columns: [], //传递给弹窗子组件的内容
      popupName: "", //传递给子组件选中弹窗的名称
      floor1: "", // 楼层
      floor2: "", // 总楼层数
      type1: "", // 室厅卫
      haselevator: "", //电梯 1有，0无
      images: [],
      popupList: {
        //所有弹窗名称对象
        floor: "", //楼层
        type: "", //户型
        trim: "", //装修
        houseType: "", //房屋类型
        orientation: "", //朝向
        years: "", //年代
        lift: "", //电梯
        ownership: "", //权属
      },
      popupConList: {
        //所有弹窗内容对象
        floor: [
          {
            values: [],
          },
          {
            values: [],
          },
        ],
        type: [
          {
            values: [
              "0室",
              "1室",
              "2室",
              "3室",
              "4室",
              "5室",
              "6室",
              "7室",
              "8室",
              "9室",
              "10室",
            ],
          },
          {
            values: [
              "0厅",
              "1厅",
              "2厅",
              "3厅",
              "4厅",
              "5厅",
              "6厅",
              "7厅",
              "8厅",
              "9厅",
              "10厅",
            ],
          },
          {
            values: [
              "0卫",
              "1卫",
              "2卫",
              "3卫",
              "4卫",
              "5卫",
              "6卫",
              "7卫",
              "8卫",
              "9卫",
              "10卫",
            ],
          },
        ],
        trim: [
          { values: ["毛坯", "简单装修", "中等装修", "精装修", "豪华装修"] },
        ],
        houseType: [
          { values: ["普通住宅", "公寓", "老公房", "别墅", "商铺", "其他"] },
        ],
        orientation: [
          {
            values: [
              "正东",
              "正西",
              "正南",
              "正北",
              "东南",
              "西南",
              "东北",
              "西北",
              "东西",
              "南北",
            ],
          },
        ],
        years: [
          {
            values: [
              "1981",
              "1982",
              "1983",
              "1984",
              "1985",
              "1986",
              "1987",
              "1988",
              "1989",
              "1990",
              "1991",
              "1992",
              "1993",
              "1994",
              "1995",
              "1996",
              "1997",
              "1998",
              "1999",
              "2000",
              "2001",
              "2002",
              "2003",
              "2004",
              "2005",
              "2006",
              "2007",
              "2008",
              "2009",
              "2010",
              "2011",
              "2012",
              "2013",
              "2014",
              "2015",
              "2016",
              "2017",
              "2018",
              "2019",
              "2020",
              "2021",
            ],
          },
        ],
        lift: [{ values: ["有", "无"] }],
        ownership: [{ values: ["商品房", "经济房", "小产权房", "自建房"] }],
      },
      imgList: [
        //上传图片列表
      ],
      videoList: [
        //上传视频列表
      ],
      ladelList: [
        //标签列表
        {
          text: "配套成熟",
          check: false,
        },
        {
          text: "绿化好",
          check: false,
        },
        {
          text: "物业好",
          check: false,
        },
        {
          text: "交通便利",
          check: false,
        },
        {
          text: "房型好",
          check: false,
        },
        {
          text: "离学校近",
          check: false,
        },
        {
          text: "离菜场近",
          check: false,
        },
        {
          text: "满五唯一",
          check: false,
        },
        {
          text: "满两年",
          check: false,
        },
        {
          text: "单价低",
          check: false,
        },
      ],
      communityId: "", //小区id
      allCheck: false, //标签是否全选
      checkedLabel: [], //选中的标签列表
      information: "", //信息补充
      content: "", //房源介绍
      showBottomFrom: false,
      i: 0, //姓名
      j: 0, // 电话
      imageSize: 0,
      videoSize: 0,
      editorOption: {
        //配置富文本编辑器
        placeholder: "请输入内容",
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            // ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["clean"], //清除字体样式
            // ["image", "video"], //上传图片、上传视频
          ],
        },
      },
    };
  },
  mounted() {
    if (this.isLogin()) {
      this.i = 0;
      window.addEventListener(
        "message",
        (event) => {
          // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
          var loc = event.data;
          if (loc && loc.module == "locationPicker") {
            //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
            this.position = loc.poiaddress;
            this.lat = loc.latlng.lat;
            this.lng = loc.latlng.lng;
            this.showMap = false;
          }
        },
        false
      );
      this.getname();
      this.getFileSize();
    }
  },
  created() {
    this.id = this.$route.query.id;
    this.uid = this.$route.query.id;
    this.resultPrice = this.$route.query.resultPrice;
    if (this.resultPrice != undefined) {
      this.evaluation = this.resultPrice;
      console.log("获取的房屋评估" + this.resultPrice);
    }
    const data = {
      id: this.id,
    };
    this.$post("/secondHouse/getSecondHouseEdit", data).then((res) => {
      if (res.status == 200) {
        if (res.data.secondHouse.hasElevator == true) {
          res.data.secondHouse.hasElevator = "有";
        } else {
          res.data.secondHouse.hasElevator = "无";
        }
        this.message = escapeHtml(res.data.secondHouse.title); //标题
        this.information = escapeHtml(res.data.secondHouse.subtitle); // 标题补充
        this.residential = res.data.secondHouse.community; //小区
        this.price = res.data.secondHouse.price; //售价
        this.content = this.$xss(res.data.secondHouse.content);
        this.popupList.type =
          res.data.secondHouse.houseType.replace(",", "室").replace(",", "厅") +
          "卫"; //户型
        this.type1 = res.data.secondHouse.houseType; //户型
        this.popupList.floor =res.data.secondHouse.floor +"层" +"共" +res.data.secondHouse.totalFloor +"层"; //楼层
        this.floor1 = res.data.secondHouse.floor; //楼层
        this.floor2 = res.data.secondHouse.totalFloor; //总楼层
        this.popupList.trim = res.data.secondHouse.finish;
        this.position = res.data.secondHouse.address;
        this.area = res.data.secondHouse.houseArea; //面积
        this.evaluation = res.data.secondHouse.assessPrice; // 估价
        this.name = res.data.secondHouse.buyerName; // 购房人
        this.phone = res.data.secondHouse.buyerMobile; // 电话
        // this.images = res.data.secondHouse.housePic; //图片
        for (var i = 0; i < res.data.images.length; i++) {
          this.imgList.push({ url: res.data.images[i] });
        }
        console.log("zzzzzzzz我的图片是什么", this.imgList);
        this.residentialid = res.data.secondHouse.communityId;
        this.videoList = [res.data.secondHouse.houseVedio];
        this.popupList.houseType = res.data.secondHouse.application;
        (this.popupList.orientation = res.data.secondHouse.orientation), //朝向
          (this.popupList.years = res.data.secondHouse.decade), //年代
          (this.popupList.lift = res.data.secondHouse.hasElevator); //电梯
        this.popupList.ownership = res.data.secondHouse.ownership; //权属
        this.checkedLabel = res.data.secondHouse.tags;
        this.paymentRadio = res.data.secondHouse.payment;
        this.money = res.data.secondHouse.downPayment;
        this.ii = res.data.secondHouse.tags.split(",");
        for (var i = 0; i < this.ladelList.length; i++) {
          for (var j = 0; j < this.ii.length; j++) {
            if (this.ladelList[i].text == this.ii[j]) {
              this.ladelList[i].check = true;
            }
          }
        }
      } else {
        // this.$toast.fail(res.msg);
      }
    });
  },
  activated() {
    if (this.$route.query.resultPrice) {
      this.evaluation = this.$route.query.resultPrice;
    }
  },
  methods: {
    nameFocus(value) {
      this.name = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
    },
    inputFocus(value) {
      this.resName = value.replace(/[^\a-\z\A-\Z0-9\u4E00-\u9FA5]/g, "");
      if (this.resName != "") {
        this.flag = true;
      } else {
        this.flag = false;
      }
    },
    getFileSize() {
      this.$post("user/getFileSize").then((res) => {
        if (res.status == 200) {
          this.imageSize = res.data.maxFileSize;
          this.videoSize = res.data.maxFileVideo;
        }
      });
    },

    releases() {
      if (!this.residential) {
        this.$toast.fail("请选择小区");
        return false;
      }
      if (!this.position) {
        this.$toast.fail("请选择地址");
        return false;
      } else if (!this.popupList.floor) {
        this.$toast.fail("请选择楼层");
        return false;
      } else if (!this.popupList.type) {
        this.$toast.fail("请选择户型");
        return false;
      } else if (!this.area) {
        this.$toast.fail("请输入面积");
        return false;
      } else if (this.area.length.length > 7) {
        this.$toast.fail("面积不能大于7位数");
        return false;
      } else if (!this.price) {
        this.$toast.fail("请输入售价，0表示面议");
        return false;
      } else if (this.price.length > 7) {
        this.$toast.fail("售价不能大于7位数");
        return false;
      } else if (!this.name) {
        this.$toast.fail("请填写联系人");
        return false;
      }
      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return false;
      } else if (!isMobile(this.phone)) {
        this.$toast.fail("手机号码格式不正确");
        return false;
      } else if (this.imgList.length==0){
        this.$toast.fail("图片不能为空");
        return false;
      }
      this.checkedLabel = [];
      //获取图片地址
      var inmlists = [];
      var videoLists = "";
      var size = 0;
      if (this.id == undefined) {
        size = 0;
        //获取图片
        for (var i = 0; i < this.imgList.length; i++) {
          if (this.imgList[i].content != undefined) {
            size += this.imgList[i].file.size;
            inmlists.push({ type: 1, url: this.imgList[i].content });
          } else {
            inmlists.push({ type: 0, url: this.imgList[i] });
          }
        }
      } else {
        size = 0;
        for (var j = 0; j < this.imgList.length; j++) {
          if (this.imgList[j].content != undefined) {
            size += this.imgList[j].file.size;
            inmlists.push({ type: 1, url: this.imgList[j].content });
          } else {
            inmlists.push({ type: 0, url: this.imgList[j].url });
          }
        }
      }
      if (this.imageSize * 1024 * 1024 < size) {
        this.$toast.fail("图片不能大于" + this.imageSize + "M");
        return false;
      }

      //获取视频
      var videosize1 = 0;
      for (var i = 0; i < this.videoList.length; i++) {
        if (this.videoList[i].content != undefined) {
          videosize1 = this.videoList[i].file.size;
          videoLists = this.videoList[i].content;
        } else {
          videoLists = this.videoList[i];
        }
      }
      if (this.videoSize * 1024 * 1024 < videosize1) {
        this.$toast.fail("视频不能大于" + this.videoSize + "M");
        return false;
      }
      //获取标签
      for (var i = 0; i < this.ladelList.length; i++) {
        if (this.ladelList[i].check != false) {
          this.checkedLabel.push(this.ladelList[i].text);
        }
      }

      const data = {
        id: this.id, //id
        title: symbolEscape(this.message), //名字
        subtitle: symbolEscape(this.information), //标题补充
        communityId: this.residentialid, //小区id
        community: this.residential, //小区
        price: this.price, //售价
        houseType: this.type1, //房型
        houseArea: this.area, //面积
        assessPrice: this.evaluation, //估价
        buyerName: this.name, // 购房人
        buyerMobile: this.phone, // 电话
        housePic: JSON.stringify(inmlists), //图片
        houseVedio: videoLists, //视频
        application: this.popupList.houseType, //用途
        orientation: this.popupList.orientation, //朝向
        decade: this.popupList.years, //年代
        hasElevator: this.haselevator, //电梯
        ownership: this.popupList.ownership, //权属
        tags: this.checkedLabel.join(","), //标签
        floor: this.floor1, //所在楼层
        totalFloor: this.floor2, //总楼层
        address: this.position, //地址
        finish: this.popupList.trim, //装修
        content: this.$xss(this.content),
        payment: this.paymentRadio,
        size: size,
        downPayment: this.money,
      };
      this.$post("user/addSecondHouse", data, {
        toast: true,
        toastName: "提交中……",
      }).then((res) => {
        let page = this;
        if (res.status == 200) {
          var router = this.$toast.success({
            message: "保存成功",
            duration: 1500,
            onClose: function () {
              page.$router.push("/my/index");
            },
          });
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    getname() {
      this.$post("user/getUserInfo").then((res) => {
        if (res.status == 200) {
          this.name = res.data.userInfo.realName;
          this.phone = res.data.userInfo.mobile;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },

    // 打开地图页面
    openMap() {
      this.showMap = true;
    },
    // 关闭地图页面
    closeMap() {
      this.showMap = false;
    },

    // 选择小区
    goResidential() {
      this.$post("user/getCommunity", name).then((res) => {
        if (res.status == 200) {
          this.residentialList = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
      this.showResidential = true;
    },
    //搜索小区
    onSearch() {
      // let data = this.resName
      const data = {
        name: this.resName,
      };
      this.$post("user/getCommunity", data).then((res) => {
        if (res.status == 200) {
          this.residentialList = res.data;
        } else {
          this.$toast.fail(res.msg);
        }
      });
    },
    // 取消搜索
    onCancel() {
      this.showResidential = false;
    },
    // 选中小区
    checkRes(index) {
      this.resIndex = index;
    },
    // 选中小区，返回表单
    confirm() {
      if (this.resIndex || this.resIndex === 0) {
        this.residential = this.residentialList[this.resIndex].name;
        this.residentialid = this.residentialList[this.resIndex].id;
        this.position =
          this.residentialList[this.resIndex].city +
          this.residentialList[this.resIndex].district +
          this.residentialList[this.resIndex].address;
        this.lat = this.residentialList[this.resIndex].lat;
        this.lng = this.residentialList[this.resIndex].lng;
        console.log("zzzz", this.residentialList[this.resIndex]);
        this.showResidential = false;
      } else {
        this.$notify({ type: "warning", message: "请选择小区" });
      }
    },
    //显示楼层弹窗
    exhibitFloor(value) {
      if (value == "floor") {
        this.popupConList.floor[0].values = [];
        this.popupConList.floor[1].values = [];
        this.popupConList.floor[0].values.push("负1层");
        for (let i = 1; i <= 100; i++) {
          this.popupConList.floor[0].values.push(i + "层");
          this.popupConList.floor[1].values.push("共" + i + "层");
        }
      }
      this.columns = this.popupConList[value];
      this.popupName = value;
      this.showPopup = true;
    },
    //关闭弹窗
    closePopup() {
      this.showPopup = false;
    },
    // 获取弹窗的值
    getValue(checkName) {
      this.popupList[this.popupName] = checkName.join("");
      if (this.popupName == "type") {
        this.popupList[this.popupName] = checkName.join("");
        this.type1 = String(checkName);
        this.type1 = this.type1
          .replace("室", "")
          .replace("厅", "")
          .replace("卫", "");
      }
      if (this.popupName == "floor") {
        this.popupList[this.popupName] = checkName.join("");
        this.floor1 = checkName[0].replace("层", "");
        this.floor2 = checkName[1].replace("层", "").replace("共", "");
      } else if (this.popupName == "lift") {
        this.haselevator = String(checkName);
        if (this.haselevator == "有") {
          this.haselevator = 1;
        } else if (this.haselevator == "无") {
          this.haselevator = 0;
        }
      }
      this.showPopup = false;
    },
    // 选择标签
    checkLabel(index) {
      this.ladelList[index].check = !this.ladelList[index].check;
      this.allCheck = this.ladelList.every((value) => {
        return value.check;
      });
    },
    // 全选标签
    allChecked() {
      this.allCheck = !this.allCheck;
      if (this.allCheck) {
        for (let i = 0; i < this.ladelList.length; i++) {
          this.ladelList[i].check = true;
        }
      } else {
        for (let i = 0; i < this.ladelList.length; i++) {
          this.ladelList[i].check = false;
        }
      }
    },
    // 显示下部分表单
    showBottom() {
      this.showBottomFrom = true;
    },
    // 跳转到估价页面
    goEvaluation(residential) {
      if (!this.residential) {
        this.$toast.fail("请选择小区");
        return false;
      }
      if (!this.position) {
        this.$toast.fail("请选择地址");
        return false;
      } else if (!this.popupList.floor) {
        this.$toast.fail("请选择楼层");
        return false;
      } else if (!this.popupList.type) {
        this.$toast.fail("请选择户型");
        return false;
      } else if (!this.area) {
        this.$toast.fail("请输入面积");
        return false;
      } else if (!this.price) {
        this.$toast.fail("请输入售价，0表示面议");
        return false;
      } else if (!this.name) {
        this.$toast.fail("请填写联系人");
        return false;
      }
      if (!this.phone) {
        this.$toast.fail("请填写手机号码");
        return false;
      } else if (!isMobile(this.phone)) {
        this.$toast.fail("手机号码格式不正确");
        return false;
      }

      console.log("有没有获取到名字", residential);
      console.log(this.floor1 + "楼层");
      console.log(this.popupList.houseType + "类型");
      console.log(this.area + "面积");
      console.log(this.popupList.orientation + "朝向");
      console.log(this.popupList.years + "年代");
      this.$dialog
        .confirm({
          title: "操作提示",
          message: "确定要进行“在线估价”",
          confirmButtonColor: "#00D3C4",
        })
        .then(() => {
          this.$router.push({
            path: "/assessment",
            query: {
              name: residential,
              floor: this.floor1,
              type: this.popupList.houseType,
              area: this.area,
              orientation: this.popupList.orientation,
              years: this.popupList.years,
              status: 0,
            },
          });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
  watch: {
    residential(val) {
      this.message =
        this.residential +
        this.popupList.type +
        this.area +
        (this.area ? "㎡" : "");
    },
    "popupList.type"(val) {
      this.message =
        this.residential +
        this.popupList.type +
        this.area +
        (this.area ? "㎡" : "");
    },
    area(val) {
      this.message =
        this.residential +
        this.popupList.type +
        this.area +
        (this.area ? "㎡" : "");
    },
  },

  components: {
    popup,
    navbar,
  },
  beforeRouteLeave(to, from, next) {
    if (to.name !== "Assessment") {
      this.$route.meta.keepAlive = false;
    } else {
      this.$route.meta.keepAlive = true;
    }
    next();
  },
};
</script>
<style lang="less">
// .edit_container,
// .quill-editor {
//   height: 300px;
// }

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
<style lang="less" scoped>
* {
  &:focus {
    outline: none !important;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
  }
}

.release {
  background: #fff;
  min-height: 100vh;
  box-sizing: border-box;
  /deep/.van-nav-bar {
    border-bottom: 1px solid #ddd;
    .van-icon {
      color: #9f9f9f;
      font-size: 18px;
    }
  }
  .release-from {
    background-color: #fff;
    min-height: calc(100vh - 50px);
    box-sizing: border-box;
    padding-bottom: 80px;
    .release-box {
      padding: 0 15px;

      .from-evaluation {
        display: flex;
        align-items: center;
        .eva-btn {
          color: #00d3c4;
          font-size: 15px;
          width: 100px;
          text-align: right;
        }
      }

      .input-right {
        margin-left: 10px;
      }
    }
    .house-msg {
      display: flex;
      align-items: center;
      .house-item {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        border-right: 1px solid #eee;
        &:last-child {
          border-right: 0;
        }
        /deep/.van-field__body input::-webkit-input-placeholder {
          color: #666;
        }
        .house-text {
          color: #878787;
          font-size: 13px;
          position: relative;
          margin-bottom: 5px;
          &.required::after {
            content: "*";
            position: absolute;
            left: -8px;
            top: 0;
            color: #ee0a24;
          }
        }
      }
    }
    .from-item {
      padding: 10px 0;
      border-bottom: 1px solid #ddd;
      /deep/.van-field__control--right {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      /deep/.van-cell {
        padding: 0;
        &::after {
          border-bottom: 0;
        }
        .van-field__label {
          padding-left: 5px;
          font-size: 15px;
        }
        &.van-cell--required::before {
          left: -2px;
        }
        input {
          font-size: 15px;
          color: #666;
        }
        input::-webkit-input-placeholder {
          color: #999;
        }
      }
      .radio-box {
        display: flex;
        font-size: 15px;
        color: #666;
        justify-content: space-between;
        /deep/.van-field__control {
          width: 70px;
          height: 26px;
        }
        /deep/.van-cell {
          width: 70px;
        }
        .Company {
          font-size: 14px;
          color: #323233;
          margin-top: 3px;
        }
      }
    }
    .from-more {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff8000;
      font-size: 14px;
      background-color: #f8f8f8;
    }
    .bottom-box {
      padding: 15px;
      /deep/.upload-img {
        .van-uploader {
          width: 100%;
          .van-uploader__wrapper {
            width: 100%;
            .van-uploader__upload,
            .van-uploader__input-wrapper img {
              width: 80px;
              height: 80px;
              margin: 0 3px 10px;
            }
            .van-uploader__preview {
              margin: 0 3px 10px;
              .van-uploader__preview-image {
                width: 80px;
                height: 80px;
              }
            }
          }
        }
      }
      .label {
        display: flex;
        align-items: flex-start;
        .label-title {
          color: #666;
          font-size: 15px;
          flex-shrink: 1;
          text-align: center;
          line-height: 28px;
          margin-right: 10px;
        }
        .label-box {
          flex: 1;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          .label-item {
            padding: 0 14px;
            height: 28px;
            line-height: 28px;
            color: #666;
            font-size: 13px;
            background-color: #f3f3f3;
            margin: 0 5px 10px;
            border-radius: 14px;
            &.active {
              color: #fff;
              background-color: #00d3c4;
            }
          }
        }
      }
      .title {
        color: #666;
        font-size: 16px;
        line-height: 45px;
      }
      .information {
        padding: 0 8px;

        /deep/.van-cell {
          border: 1px dashed #ddd;
          padding: 10px;
          .van-field__control {
            font-size: 12px;
            line-height: 15px;
            color: #666;
          }
        }
      }
      .introduce {
        padding: 0 8px;
        /deep/.ql-editor {
          min-height: 200px;
          max-height: 400px;
        }
      }
    }
  }
  .res-box {
    /deep/.van-search {
      position: fixed;
      left: 0;
      right: 0;
      top: 46px;
      border-bottom: 1px solid #ddd;
      height: 50px;
    }
    .res-content {
      background-color: #fff;
      padding: 50px 15px 60px;
      .res-item {
        padding: 10px 0;
        border-bottom: 1px solid #ddd;
        display: flex;
        &:last-child {
          border-bottom: 0;
        }
        .item-check {
          margin-right: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 20px;
            height: 20px;
          }
          .check-box {
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid #ccc;
            box-sizing: border-box;
          }
        }
        .item-img {
          img {
            width: 120px;
            height: 80px;
            margin-right: 10px;
          }
        }
        .item-msg {
          .item-name {
            color: #333;
            font-size: 15px;
          }
          .item-desc {
            color: #858585;
            font-size: 12px;
          }
        }
      }
    }
  }
  .from-buttom {
    position: fixed;
    bottom: 0;
    height: 60px;
    padding: 0 15px;
    box-sizing: border-box;
    border-top: 1px solid #ddd;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    background-color: #fff;
    /deep/.van-button--default {
      border: 0;
      height: 40px;
      background: #00d3c4;
      color: #fff;
      border-radius: 5px;
      letter-spacing: 1px;
    }
  }
}
</style>